import React from "react";
import Stable from "../../Components/SchemaTable/Stable";
const CustomSchema = () => {
  return (
    <>
      <Stable />
    </>
  );
};

export default CustomSchema;
